.App {
  text-align: center;
  background-color: black;
  color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

body{
  background-color: black;
}

.card-body{
  background-color: black;
  border-radius: 0px 0px 5px 5px;
  color:rgb(183, 183, 183)
}

.navbar-dark {
  background-color: rgb(37, 37, 37);
  opacity: .9;
}

#tagline {
  color: rgb(183, 183, 183);
  size: 42px;
}

.site-footer{
  margin-top: 24px;
  border-top: solid 1px;
  border-color: #00a99d
}

.image-card{
  background-color:rgb(37, 37, 37);
}

h5{
  color:rgb(183, 183, 183);
  padding-top: 20px;
  padding-bottom: 16px;
}

.btn{
  background-color: none;
}

iframe{
  display: block;
}

.h5link{
  color: #00a99d;
}

.h5link:hover{
  color: aliceblue;
}

  

